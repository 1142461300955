<template>
  <section>
    Контакты
  </section>
</template>

<script>
export default {
  name: 'Help'
}
</script>